<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import * as Command from '$lib/components/ui/command';
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { getPrimaryRoutes } from '$lib/config/routes';
  import { guardFinanceAccess } from '$lib/auth/guards';
  import { trashStore } from '$lib/stores/trash-store';
  import { onMount } from 'svelte';
  import { Progress } from '$lib/components/ui/progress';

  let isOpen = false;
  let showEmptyConfirm = false;
  let emptyingTrash = false;
  let progress = 0;
  let currentItem = 0;
  let totalItems = 0;

  // Load trash items on component mount
  onMount(() => {
    trashStore.loadTrashItems();
  });

  async function handleRestore(item: any) {
    await trashStore.restoreItem(item.module, item.id);
  }

  async function handleDelete(item: any) {
    await trashStore.deleteItemPermanently(item.module, item.id);
  }

  async function handleEmptyTrash() {
    try {
      emptyingTrash = true;
      progress = 0;

      // Process one by one
      const items = [...$trashStore];
      totalItems = items.length;
      currentItem = 0;

      for (let i = 0; i < items.length; i++) {
        currentItem = i + 1;
        const item = items[i];

        // Process each item individually
        await trashStore.deleteItemPermanently(item.module, item.id);

        // Update progress after each item
        progress = Math.round(((i + 1) / items.length) * 100);

        // Small delay between items to allow UI updates
        await new Promise(resolve => setTimeout(resolve, 50));
      }

      showEmptyConfirm = false;
      emptyingTrash = false;
      progress = 100;
    } catch (error) {
      console.error('Error emptying trash:', error);
      emptyingTrash = false;
    }
  }

  // Get icon based on item type
  function getItemIcon(type: string) {
    return getPrimaryRoutes().find(route => route.module === type)?.icon;
  }

  function openItem({ module, id }) {
    appStore.select({
      module,
      id,
    });
    appStore.openPopup(true);
    isOpen = false;
  }
</script>

<!-- Trigger Button -->
<div class="my-2">
  <button
    class="flex relative gap-2 items-center px-2 pt-5 pb-4 w-full text-sm rounded-md hover:bg-muted group"
    on:click={() => (isOpen = true)}
  >
    <div class="flex gap-2 items-center">
      <Icon
        icon="mdi:trash-can"
        class={cn(
          'text-muted-foreground size-5',
          $appStore.isCollapsed ? 'ml-2' : '',
        )}
      />
      {#if !$appStore.isCollapsed}
        <span>Trash</span>
      {/if}
    </div>

    {#if $trashStore?.length > 0}
      <div
        class={cn(
          'absolute flex items-center justify-center',
          $appStore.isCollapsed ? 'right-1 top-1' : 'right-2',
          'min-w-[20px] h-5 rounded-full bg-muted px-1 text-xs',
          'group-hover:bg-muted-foreground/20',
        )}
      >
        {$trashStore.length}
      </div>
    {/if}
  </button>
</div>

<Command.Dialog bind:open={isOpen}>
  <Command.Input placeholder="Search trash items..." />
  <Command.List class="max-h-[400px] overflow-y-auto">
    <Command.Empty>No trash items found.</Command.Empty>

    {#if !$trashStore}
      <Command.Loading>Loading trash items...</Command.Loading>
    {:else if $trashStore.length === 0}
      <Command.Item>Trash is empty</Command.Item>
    {:else}
      <Command.Group heading="Recently Deleted">
        {#each $trashStore as item}
          <Command.Item
            class="flex justify-between items-center cursor-pointer"
            onSelect={() => openItem(item)}
          >
            <div class="flex gap-2 items-center">
              <Icon
                icon={getItemIcon(item.module)}
                class="text-muted-foreground size-4"
              />
              <span>{item.name?.replace(/"/g, ' ')}</span>
              <span class="text-xs text-muted-foreground">
                {item.module}
              </span>
              <span class="text-xs text-muted-foreground hidden">
                {item.id}
              </span>
            </div>
            <div class="flex gap-2">
              <button
                class="p-1 rounded hover:bg-primary/10 hover:text-primary"
                on:click|stopPropagation={() => handleRestore(item)}
              >
                <Icon icon="mdi:restore" class="size-4" />
                <span class="sr-only">Restore</span>
              </button>
              {#if guardFinanceAccess()}
                <button
                  class="p-1 rounded hover:bg-destructive/10 hover:text-destructive"
                  on:click|stopPropagation={() => handleDelete(item)}
                >
                  <Icon icon="mdi:delete-forever" class="size-4" />
                  <span class="sr-only">Delete permanently</span>
                </button>
              {/if}
            </div>
          </Command.Item>
        {/each}
      </Command.Group>
    {/if}
  </Command.List>

  {#if $trashStore?.length > 0}
    <div class="flex justify-between items-center p-2 border-t">
      <span class="text-sm text-muted-foreground">
        {$trashStore.length} item{$trashStore.length === 1 ? '' : 's'}
      </span>
      {#if guardFinanceAccess()}
        <button
          class="text-sm text-destructive hover:text-destructive/80"
          on:click={() => (showEmptyConfirm = true)}
          disabled={emptyingTrash}
        >
          Empty Trash
        </button>
      {/if}
    </div>
  {/if}
</Command.Dialog>

<AlertDialog.Root bind:open={showEmptyConfirm}>
  <AlertDialog.Content class="z-[60]">
    <AlertDialog.Header>
      <AlertDialog.Title>Empty Trash?</AlertDialog.Title>
      <AlertDialog.Description>
        This will permanently delete all items in the trash. This action cannot
        be undone.
      </AlertDialog.Description>
    </AlertDialog.Header>

    {#if emptyingTrash}
      <div class="my-4">
        <div class="flex justify-between items-center mb-2">
          <span class="text-sm">
            Processing item {currentItem} of {totalItems}
          </span>
          <span class="text-sm font-medium">{progress}%</span>
        </div>
        <Progress value={progress} class="w-full" />
      </div>
    {/if}

    <AlertDialog.Footer>
      <AlertDialog.Cancel disabled={emptyingTrash}>Cancel</AlertDialog.Cancel>
      <AlertDialog.Action asChild>
        {#if guardFinanceAccess()}
          <Button
            variant="destructive"
            on:click={handleEmptyTrash}
            disabled={emptyingTrash}
          >
            {#if emptyingTrash}
              <Icon icon="mdi:loading" class="mr-2 size-4 animate-spin" />
              Processing...
            {:else}
              Empty Trash
            {/if}
          </Button>
        {/if}
      </AlertDialog.Action>
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
