import { get } from 'svelte/store';
import { updateItem } from './mutations';

import { supabase } from './supabase';
import { getPrimaryRoutes } from '$lib/config/routes';
import { authStore } from '$lib/stores/auth-store';
import { guardFinanceAccess } from '$lib/auth/guards';

export const getTrashItems = async () => {
  const modules = getPrimaryRoutes().map(route => route.module);
  const data = await Promise.all(
    modules
      .filter(
        el =>
          el !== 'dashboard' &&
          el !== 'crm' &&
          el !== 'finance' &&
          el !== 'analytics',
      )
      .map(async module => {
        const { data } = await supabase(get(authStore)?.token)
          .from(module)
          .select(`*`)
          .eq('deleted', true);
        return data?.map(item => ({ ...item, module }));
      }),
  );
  return data.flat().filter(item => item);
};

export async function restoreItem(module, id) {
  await updateItem(module, id, {
    deleted: false,
    deletedAt: null,
    deletedBy: null,
  });
}

export async function deleteItemPermanently(module: string, id: string) {
  if (!guardFinanceAccess()) return;
  if (!module || !id) return;
  if (module === 'accounts') {
    await supabase(get(authStore)?.token)
      .from('invoices')
      .update({ customer: null })
      .eq('customer', id)
      .in('status', [
        'Paid',
        'Draft',
        'Uncollectable',
        'Void',
        'Cancellation invoice',
        'Cancelled',
      ]);
  }
  await supabase(get(authStore)?.token).from(module).delete().eq('id', id);
}

export async function emptyTrash() {
  if (!guardFinanceAccess()) return;
  const items = await getTrashItems();
  await Promise.all(
    items.map(item => deleteItemPermanently(item.module, item.id)),
  );
}
